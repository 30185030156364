import * as React from 'react'
import Layout from '../../templates/layout'
import SloganPage from '../../components/molecules/SloganPage'
import Seo from '../../helpers/seo'
import HeaderContentSolution from '../../components/organisms/HeaderContentSolution'
import ContentSolution from '../../components/organisms/ContentSolution'
import CardsInformation from '../../components/molecules/CardsInformation'
import squadsJson from './squads.json'
import ContactForm from '../../components/organisms/ContactForm'
import CasesRelational from '../../components/organisms/CasesRelational'
import { assignIDToSolutionsJson } from '../../helpers/assignIDToSolutionsJson'

const ServicesOutsourcing = () => {
  const { squadsEstendidas, squadsExclusivas, alocation, outsourcing, relational } =
    assignIDToSolutionsJson(squadsJson)

  const cardContent = [
    {
      id: 1,
      heading: 'Squads Estendidas_',
      content: () => {
        return squadsEstendidas.map((data) => {
          return <ContentSolution description={data.description} key={data.id} />
        })
      },
      label: 'orange-dark',
      selected: 'close',
    },
    {
      id: 2,
      heading: 'Squads Exclusivas_',
      content: () => {
        return squadsExclusivas.map((data) => {
          return <ContentSolution description={data.description} key={data.id} />
        })
      },
      label: 'orange-light',
      selected: 'close',
    },
    {
      id: 3,
      heading: 'Outsourcing_',
      content: () => {
        return outsourcing.map((data) => {
          return <ContentSolution description={data.description} key={data.id} />
        })
      },
      label: 'orange-dark',
      selected: 'close',
    },
    {
      id: 4,
      heading: 'Alocação_',
      content: () => {
        return alocation.map((data) => {
          return <ContentSolution description={data.description} key={data.id} />
        })
      },
      label: 'orange-light',
      selected: 'close',
    },
  ]

  return (
    <Layout page="ServicesOutsourcing">
      <SloganPage title={'Outsourcing e Alocação'} type={'squads'} />
      <HeaderContentSolution
        icon={'squads'}
        description={`<p>Em um ambiente empresarial dinâmico, a flexibilidade, a competência e rapidez são essenciais. 
        Nosso serviço de Outsourcing e Alocação de desenvolvedores proporciona isso, oferecendo Squads Estendidas, Squads Exclusivas, 
        e profissionais qualificados para impulsionar seus projetos. Com essas soluções, você pode expandir sua equipe com eficiência e 
        alinhamento, garantindo um alto nível de desempenho, qualidade e integração cultural.</p>`}
      />
      <CardsInformation cardContent={cardContent} />
      <ContactForm />
      <CasesRelational
        relationalData={relational}
        currentCategory="Outsourcing e Alocação"
      />
    </Layout>
  )
}

export const Head = () => <Seo title="Serviços" />

export default ServicesOutsourcing
